import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ title, description, imgSrc, link, año }) => {
  return (
    <div className="card m-2 p-2">
      <div className="row g-0">
        <div className="col-md-4">
          <img src={imgSrc} className="img-fluid m-2" alt={title} />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <br/>
            <p className="card-text">{description}</p>
            <span className="btn rounded-pill">{año}</span>
            <a href={link} target="_blank" rel="noopener noreferrer" className="btn btn-info rounded-pill">Visualizar</a>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  año: PropTypes.number.isRequired
};

export default Card;
